.Feed {

  margin-top: 3rem;

  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // gap: 1rem;

  // margin-top: 2rem;
  // margin-bottom: 5rem;

  .photograph {
    cursor: pointer;
  }
}