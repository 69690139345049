@import './fonts/fonts.scss';

.App {
  color: rgb(36, 36, 36);
  font-family: Arial, Helvetica, sans-serif;
}

.link-button {
  text-decoration: none;
}

.empty-prompt{
  margin-left: 2rem;
  font-style: italic;
  color: grey;
}

button, .link-button {

  background: white;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  color: black;
  font-weight: bold;

  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  transition: all 0.2s;

  &:hover{
    color: white;
    background: rgb(24, 24, 24);
    border: 1px solid rgb(24, 24, 24);
  }
}

button:disabled,
button[disabled]{
  color: black;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  cursor: default;
  // cursor: pointer;

  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover{
    color: black;
    background: white;
    border: 1px solid rgb(24, 24, 24);
  }
}

input {
  box-sizing: border-box;
  padding: 0.5rem;
}