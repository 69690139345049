.ProfilePage {

  padding: 2rem;

  .account-actions{

    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // place-self: start center;
    
    gap: 1rem;
    display: flex;

    .photograph-upload{

      background: rgb(242, 242, 242);
      box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.19);
      -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.19);
      -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.19);
      padding: 1rem;
      border-radius: 1rem;
  
      display: flex;
      flex-direction: column;

      .title-div{

        display: flex;
        align-items: center;

        .title{
          font-weight: bold;
          color: rgba(0, 0, 0, 0.781);
        }
  
        .loading-gif{
          width: 3rem;
          margin-left: 1rem;
        }

      }
  
  
      input {
        width: 100%;
      }
  
      .metadata-form {
        
        padding-top: 1.5rem;
  
        // label {
        //   margin-bottom: 1rem;
        // }
  
        input {
          box-sizing: border-box;
          margin-bottom: 1rem;
          margin-top: 0.5rem;
          padding: 0.5rem;
        }
  
        textarea {
          display: block;
          box-sizing: border-box;
          margin-bottom: 1rem;
          margin-top: 0.5rem;
          padding: 0.5rem;
          width: 100%;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 0.8rem;
        }
      }
  
      .upload-button{
        margin-top: 1rem;
      }
  
    }
  }

}