@import '../fonts/fonts.scss';

.Header {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  padding: 2rem;
  background: rgb(22, 27, 32);

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }

  .profile-pic {
    height: 5rem;
    width: 5rem;
    border-radius: 2.5rem;
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.19);
    margin-left: 2rem;

    @media screen and (max-width: 820px) {
      margin-left: 0;
      order: 0;
    }

  }

  a {
    display: block;
  }

  a.text-link {
    color: rgb(219, 219, 219);
    text-decoration: none;
    margin-right: 2rem;
    font-weight: bold;

    @media screen and (max-width: 820px) {
      margin-right: 0;
    }

    transition: all 0.2s;

    &:visited {
      color: rgb(219, 219, 219);
      text-decoration: none;
    }

    &:hover {
      color: rgb(255, 255, 255);
    }
  }

  a.title {
    font-size: 5rem;
    font-family: 'Mervale';
    padding-bottom: 1rem;

    @media screen and (max-width: 820px) {
      order: 1;
    }
  }

  .account-div {

    margin-left: auto;

    @media screen and (max-width: 820px) {
      margin-left: 0;
      order: 2;
    }

    .info-icon {
      color: white;
      font-size: 1.2rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    .metamask-button {
      // margin-left: auto;
      background: rgba(255, 255, 255, 0);
      border: 1px solid rgb(255, 255, 255);
      color: white;

      &:hover,
      &[disabled] {
        color: rgb(32, 32, 32);
        background: rgb(223, 223, 223);
        background: rgb(223, 223, 223);
      }
    }
  }
}
